import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/digit/Documents/sites/lovelyladyverse/src/components/layout/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hello world`}</h2>
    <p>{`This is a test markdown page. Go to `}<a parentName="p" {...{
        "href": "https://google.com"
      }}>{`google`}</a></p>
    <ul>
      <li parentName="ul">{`Hello`}</li>
      <li parentName="ul">{`Hello`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      